







































import { Vue, Component } from "vue-property-decorator";
import { api_goodsCategory, goodsCategory } from "@/http/goodsCategory";
import PageHeader from "@/components/pageHeader/index.vue";
import { ElForm } from "element-ui/types/form";
import {
  provinceAndCityData,
  regionData,
  CodeToText,
} from "element-china-area-data";

@Component({
  components: {
    PageHeader,
  },
})
export default class extends Vue {
  info: goodsCategory = {
    id: "",
    name: "",
    desc:"",
    remark:"",
    create_time: "",
    update_time: "",
  };

  options = provinceAndCityData;
  selectedOptions: string[] = [];

  rules = {
    name: [{ required: true, message: "请填写分类名称" }],
  };

//   handleChange() {
//     this.info.cityCode = this.selectedOptions[1];
//     this.info.provinceCode = this.selectedOptions[0];
//     regionData.forEach((item: any) => {
//       if (item.value == this.info.provinceCode) {
//         item.children.forEach((e: any) => {
//           if (e.value === this.info.cityCode) {
//             this.info.region = e.children;
//           }
//         });
//       }
//     });
//     this.info.city =
//       CodeToText[this.selectedOptions[1]] === "市辖区"
//         ? CodeToText[this.selectedOptions[0]]
//         : CodeToText[this.selectedOptions[1]];
//     this.info.province = CodeToText[this.selectedOptions[0]];
//   }

  async submit() {
    await (this.$refs["form"] as ElForm | undefined)?.validate();
    if (this.id) {
      await api_goodsCategory.edit(this.info);
    } else {
      await api_goodsCategory.create(this.info);
    }
    this.$message.success("修改成功");
    this.$router.go(-1);
  }

  async get_info() {
    this.info = await api_goodsCategory.get_info(this.id as number);
    // this.options = options;
  }

  get id(): undefined | number {
    return this.$route.query.id as any;
  }

  created() {
    if (this.id === undefined) return;
    this.get_info();
  }
}
